<template>
<div class="app-main">
  <div class="app-container">
    <div class="position">
      <h2>公司简介</h2>
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>公司简介</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="pos_cont">
      <h3>一、公司成立时间</h3>
      <p>秦皇岛精方健康管理有限公司成立于2008年，注册资金一千万元，注册商标“晟怡精方”和“大医精方”。</p>
      <h3>二、发展历程和愿景</h3>
      <p>秦皇岛精方健康管理有限公司是我国首家“中医互联网+”模式落地的实践者。现有分院三家、中医智慧康养社区4家、培训基地一座、智慧康养驿站6个，同时2019年将在秦皇岛市区成立19家中医智慧康养社区，与山海关人民医院、开发区医院和开发区第二医院家联合成立3家医养康复中心，在首都北京成立智慧康养工作驿站150个。公司将以秦皇岛为依托，协同京津冀一体化发展，规划在未来3到5年内上市，实现全国2000家以上的连锁规模，打造中医医养康复领域航母旗舰品牌。</p>
      <h3>三、理论与传承</h3>
      <p>大医精方在汲取传统中医的“三大平衡理论”的基础之上，探索出了“五大干预体系、六大基本步骤，“五味子疗法””。大医精方综合运用上述理论为客户制定“一对一”个性化调理与养生方案，并运用中医按摩、刮痧、拔罐、针灸、药敷等物理疗法及汤剂、丸、散、膏、丹等中药疗法对客户进行治疗与调养。通过建立健康追踪管理体系，长期追踪和维护客户的健康状况，确保治疗落到实处，达到最佳的治疗效果。经过不断的总结与大量的临床实践，目前在颈、肩、腰、腿痛五项病治疗、疲劳综合症调理、疾病预防等方面已经取得了巨大成就。</p>
      <h3>四、企业宗旨</h3>
      <p>以弘扬中医文化为己任，通过运用中医古法养生“五味子疗法”为核心，从而完成“治已病、治未病”目标，最终实现居民健康自我管理，养成健康生活方式。</p>
      <h3>五、企业目标</h3>
      <p>大医精方立志于打造中医康养领域航母旗舰品牌；实现中国首家中医类大学生创业孵化器运行平台；成为国内康养O2O+C2B连锁集团标杆企业。</p>
      <h3>六、企业的核心价值观</h3>
      <p>以仁为本&nbsp;&nbsp;&nbsp;以诚为根&nbsp;&nbsp;&nbsp;以典为范&nbsp;&nbsp;&nbsp;以素为髓&nbsp;&nbsp;&nbsp;以德修行&nbsp;&nbsp;&nbsp;以爱修心&nbsp;&nbsp;&nbsp;以博修业&nbsp;&nbsp;&nbsp;以礼修为</p>
      <h3>七、企业技术管理理念：PDCA质量管理循环理论</h3>
      <p><img src="@/assets/img/1-1ZQG32422594.png" style="width: 250px; height: 200px;"></p>
      <h3>八、企业品牌文化内涵：晟怡精方</h3>
      <ol>
        <li>晟：晟者光明也，完美之意；</li>
        <li>怡：怡者和也，悦也，快乐之意；</li>
        <li>精：精者择也，精髓之意；</li>
        <li>方：方者剂也，单人单方之意。</li>
      </ol>
      <h3>九、企业行业荣誉：</h3>
      <ol>
        <li>1、2009年，中国中医质量管理循环体系PDCA推广单位</li>
        <li>2、2011年，中国传统功法传承与实践单位</li>
        <li>3、2012年，中国首家集团化中医连锁运营模式单位</li>
        <li>4、2013年，中国民间中医医药研究开发协会成员单位</li>
        <li>5、2015年，全国“互联网+中医”实践单位</li>
        <li>6、2016年，中国中医药文化工作管理委员会成员单位</li>
        <li>7、2016年，全国“绿荫行动”工程管理办公室业务推广单位</li>
        <li>8、2016年，秦皇岛市工商业联合会成员单位</li>
        <li>9、2016年，河北省首届中医校企联盟主办单位</li>
        <li>10、2016年，秦皇岛市中医行业重点科技推荐单位</li>
        <li>11、2016年，邢台医学高等专科学院实习培训、就业基地</li>
        <li>12、2016年，秦皇岛市卫生学校实习培训、就业基地</li>
        <li>13、2016年，河北科技师范学院实习培训、就业基地</li>
        <li>14、2016年，北方学院实习培训、就业基地</li>
        <li>15、2016年，沧州医学高等专科学院实习培训、就业基地</li>
        <li>16、2016年，沧州渤海专修学院实习培训、就业基地</li>
        <li>17、2016年，唐山职业技术学院实习培训、就业基地</li>
        <li>18、2016年，承德护理学院实习培训、就业基地</li>
        <li>19、2017年，全国中医类大学生创业孵化发起单位</li>
      </ol>
      <h2>公司产业链集群</h2>
      <h3>1、中国中医药产业大学生创业孵化器</h3>
      <p>2016年，北戴河获批国家首个生命健康产业示范区，《示范区发展总体规划》经国家发改委等13个部委正式批准实施。2017年大医精方与北戴河区政府联合打造的中医药产业大学生创业孵化器项目将正式落地北戴河区，此产业不仅为中医药大学生提供了自主创业平台，解决中医大学生出路问题，也将为中医的传承、弘扬与发展作出巨大贡献，同时中医药大学生创业项目的纷纷落成，也必将为大医精方迅速复制、壮大和腾飞提供强大的发展契机，实现中医连锁产业链。</p>
      <h3>2、线上大健康“中医互联网+”产业</h3>
      <p>大医精方独立自主研发的“精方云健康APP”已经完成内测及全部对接业务，将于2017年上半年正式入驻各大下载平台及进行全国推送运营。精方云健康APP是国内首家实现居民健康自我管理的专业、精准“中医互联网+”平台，平台以居民健康自我评估为入口，为居民提供个性化健康指导方案，实现居民健康自我管理，另外平台还提供养生咨询系统、中西医专家在线问诊系统、精方早茶、精方母婴系统和精方商城等增值服务。</p>
      <p>精方云平台的运行，必将完善“中医互联网+”市场体系，中医调理O2O市场有望迎来一次爆发式的发展。</p>
      <h3>3、线下实体店传统中医“自然疗法”生命健康产业</h3>
      <p>线下实体店运用传统中医“自然疗法”中的砭、针、灸、导引、按跷五大技法，结合中医五大干预体系，为客户提供私人订制、单人单方的调理和调治方案。服务对象包含颈肩腰腿痛人群、亚健康人群以及慢性病人群。</p>
      <p>同时大医精方运用先进的PDCA质量管理循环体系，将中医疾病调治、调理、治未病操作流程进行了标准化、规范化、模式化、流程化的管理，解决了传统中医不能大规模复制的瓶颈，实现了公司直营、加盟、医院、社区医联体产业链模式。</p>
    </div>
  </div>
</div>
</template>

<script>
export default {
  data() {
    return {
    };
  },
  methods: {
  },
  created() {
  },
};
</script>

<style lang="scss" scoped>
h3{
  margin: 30px 0;
  color: #603a00;
}
p{
  text-indent: 35px;
  color: #606266;
}
ol li{
  text-indent: 35px;
  margin-bottom: 10px;
  color: #606266;
}
</style>